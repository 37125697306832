@font-face { /* -> THIN */
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-thin.woff2') format('woff2'),
         url('../fonts/roboto/roboto-thin.woff') format('woff'),
         url('../fonts/roboto/roboto-thin.ttf') format('truetype');
    font-weight: 200;
    font-style:normal; }
@font-face { /* -> THIN */
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-thinitalic.woff2') format('woff2'),
         url('../fonts/roboto/roboto-thinitalic.woff') format('woff'),
         url('../fonts/roboto/roboto-thinitalic.ttf') format('truetype');
    font-weight: 200;
    font-style:italic; }
@font-face { /* -> LIGHT */
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-light.woff2') format('woff2'),
         url('../fonts/roboto/roboto-light.woff') format('woff'),
         url('../fonts/roboto/roboto-light.ttf') format('truetype');
    font-weight: 300;
    font-style:normal; }
@font-face { /* -> LIGHT */
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-lightitalic.woff2') format('woff2'),
         url('../fonts/roboto/roboto-lightitalic.woff') format('woff'),
         url('../fonts/roboto/roboto-lightitalic.ttf') format('truetype');
    font-weight: 300;
    font-style:italic; }
@font-face { /* -> REGULAR */
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-regular.woff2') format('woff2'),
         url('../fonts/roboto/roboto-regular.woff') format('woff'),
         url('../fonts/roboto/roboto-regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal; }
@font-face { /* -> ITALIC */
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-italic.woff2') format('woff2'),
         url('../fonts/roboto/roboto-italic.woff') format('woff'),
         url('../fonts/roboto/roboto-italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic; }
@font-face { /* -> MEDIUM */
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-medium.woff2') format('woff2'),
         url('../fonts/roboto/roboto-medium.woff') format('woff'),
         url('../fonts/roboto/roboto-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal; }
@font-face { /* -> MEDIUM */
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-mediumitalic.woff2') format('woff2'),
         url('../fonts/roboto/roboto-mediumitalic.woff') format('woff'),
         url('../fonts/roboto/roboto-mediumitalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic; }
@font-face { /* -> BOLD */
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-bold.woff2') format('woff2'),
         url('../fonts/roboto/roboto-bold.woff') format('woff'),
         url('../fonts/roboto/roboto-bold.ttf') format('truetype');
    font-weight: 700;
    font-style:normal; }
@font-face { /* -> BOLD */
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-bolditalic.woff2') format('woff2'),
         url('../fonts/roboto/roboto-bolditalic.woff') format('woff'),
         url('../fonts/roboto/roboto-bolditalic.ttf') format('truetype');
    font-weight: 700;
    font-style:italic; }
@font-face { /* -> BLACK */
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-black.woff2') format('woff2'),
         url('../fonts/roboto/roboto-black.woff') format('woff'),
         url('../fonts/roboto/roboto-black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal; }
@font-face { /* -> BLACK */
    font-family: 'roboto';
    src: url('../fonts/roboto/roboto-blackitalic.woff2') format('woff2'),
         url('../fonts/roboto/roboto-blackitalic.woff') format('woff'),
         url('../fonts/roboto/roboto-blackitalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic; }