:root {
    --main_blue: #00AEEF;
    --sec_blue: #0b709a;
    --pale_blue: #eaf6fe;
    --pale2_blue: #bce4fa;
    --pale3_blue: #83d0f5;
    --txt_black_1: #130e09;
    --white: #ffffff;
    --header1: #00b1eb;
    --header2: #5bc5f2; 
    --main: #751a71; 
    --main_dark: #360134; 
    --main_light: #e2d4e2; }

html, body { scroll-behavior: smooth ;font-weight: 400; }
html {
    overflow-x: hidden;
}

  /*************/
 /*  CONTENT  */
/*************/
.contentLink { color: var(--main_blue); }
.contentLink:hover { color: var(--sec_blue); }

.pages {
    width: 100%;
    min-height: 100%;
    background-color: white;
    padding: 2rem 4rem 5rem 4rem; }
.article_content {
    text-align: left;
    padding-top: 0.5rem;
    padding-bottom: 2rem; }
    .article_content p {
        padding-bottom: 1.5rem; }

.infoBox {
  border: solid 1px var(--txt_black_1);
  padding: 0.65rem;
  margin: 0.5rem 0;
  font-size: 0.75rem;
  line-height: 1rem; }

div {
  white-space: pre-wrap; }

.text-xxs {
  font-size: 0.5rem;
  line-height: 0.75rem; }

.infoBox span,
.sup { 
    padding: 0 2px;
    vertical-align: super; }

.col2 {
    column-count: 2;
    column-gap: 3rem; }
.noColumnBreak {
    break-inside: avoid;}

@media (max-width: 767px) {
    .col2 { column-count: 1; }
    .pages { padding: 1.5rem 3rem 5rem 3rem; }
}
@media (max-width: 511px) {
    .pages { padding: 1rem 2rem 5rem 2rem; }
}

.footnote {
    color: var(--main_blue)}

  /*************/
 /*  BULLETS  */
/*************/  
.bullets {
  padding-left: 30px;
  padding-bottom: 2rem; }
.bullets li {
  list-style-type: disc; }
.bullets ul {
  padding-left: 20px; }
.bullets ul li {
  list-style-type: circle; }

.bullets.pb-0 { 
  padding-top: 0.5rem;
  padding-bottom: 0; }

.alphaList {
  list-style-type: lower-alpha; }
li p { padding-bottom: 0.5rem !important;}

  /************/
 /*  TABLES  */
/************/

table td,
table th {
  padding: 1px 4px;
  vertical-align: top; }

.allBorders,
.allBorders th,
.allBorders td {
  border: solid 1px var(--main);
  vertical-align: top; }
.allBorders tr td:first-child {
  color: var(--main); }
.allBorders thead th {
  font-weight: 300; }
.allBorders p,
.allBorders .bullets {
  padding-bottom: 0.5rem; }

.tableRightBorder tr td:not(:last-child) {
    border-right: 2px solid var(--main); }
.tableRightBorder p { padding: 0; }

.tableTextLR th,
.tableTextLR td {
    text-align:left; }
.tableTextLR td + td, 
.tableTextLR th + th {
    text-align:right; }


.tableStripes tbody tr:nth-child(odd) {
  background-color: var(--white); } 
.tableStripes tbody tr:nth-child(even) {
  background-color: var(--main_light); } 

  /******************/
 /*  TABLE CIRCLE  */
/******************/
.tableCircle {
  position: absolute;
  top: 6px;
  right: 4px;
  width: 12px; 
  height: 12px;
  border-radius: 50%; }
.tableCircle.orange {
  background-color: #f36c21; }
.tableCircle.yellow {
  background-color: #ffc20e; }

  /******************/
 /*  TABLE SCROLL  */
/******************/
.table-scroll {
    position: relative;
    width:100%;
    z-index: 1;
    overflow-x: scroll; }
.table-scroll table {
    width: 100%;
    min-width: 900px;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0; }
    
/* .table-scroll thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0; } */
/* .table-scroll tfoot,
.table-scroll tfoot th,
.table-scroll tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index:4; } */
    
.table-scroll table th:first-child,
.table-scroll table td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2; }


.table-scroll .tableStripes tbody tr:nth-child(odd) td:first-child {
  background-color: var(--white); } 
.table-scroll .tableStripes tbody tr:nth-child(even) td:first-child {
  background-color: var(--main_light); } 


.dTable {
    table-layout: fixed;
    width: 100%;
    background-color: var(--white); }

.dTable tr th:first-child {
    background-color: var(--main);
    width: 180px; 
    height: 50px;}
  
.dTable.notFixed {
  table-layout: auto; }

.dTable .topHeader {
    text-align: center !important;
    background-color: var(--main);
    color: white; 
    border: 1px solid var(--main_light)}
.dTable .botHeader {
    color: var(--white);
    padding: 2px 10px; }
.dTable .botHeader:hover {
    color: var(--sec_blue); }
.dTable .female {
    background-color: var(--header1);}
.dTable .male,
.dTable .total {
    background-color: var(--header2);}
.dTable th .gender {
    margin-top: 1px;
    padding-right: 2px; }
.dTable th .sort {
    font-size: 0.75rem;
    padding-left: 4px; }
.dTable tfoot {
    font-weight: 500;
    background-color: var(--pale3_blue);}
.dTable tfoot td:first-child {
    background-color: var(--pale3_blue);}

@media (max-width: 511px) {
    table {
        font-size: 0.875rem;
        line-height: 1.25rem; }
}

  /***************/
 /*  ANIMATION  */
/***************/
.ani03s {
    -webkit-transition: all 0.3s linear;
        -moz-transition: all 0.3s linear;
          -o-transition: all 0.3s linear;
        -ms-transition: all 0.3s linear;
            transition: all 0.3s linear; }

  
  /**************/
 /*  SORT BOX  */
/**************/          
.sortBox {
  display: flex;
  flex-wrap: nowrap; }
.sortBox .left_box,
.sortBox .right_box {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  border: solid 1px var(--main_blue);
  margin-bottom: 1rem; }
.sortBox .left_box {
  flex-grow: 1;
  margin-right: 4rem; }

@media (max-width: 767px) {
  .sortBox .left_box { margin-right: 3rem; }
}
@media (max-width: 639px) {
  .sortBox .left_box { margin-right: 2rem; }
}
@media (max-width: 511px) {
  .sortBox {
    flex-direction: column; }
  .sortBox .left_box,
  .sortBox .right_box {
    flex-wrap: wrap;
    width: 100%; }
}

  /****************/
 /*  CHECKBOXES  */
/****************/
.round_checkbox_box {
    position: relative;
    width: 1rem;
    height: 1rem; }
.center input[type=checkbox] + label, 
.center input[type=checkbox] {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
.center input[type=checkbox] {
    opacity: 0;
    pointer-events: none; }
.center input[type=checkbox] + label {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: solid 1px var(--main);
    cursor: pointer;
    box-shadow: 1px 1px 1px 1px rgba(3, 79, 32, 0.5);
    transition: box-shadow 0.4s cubic-bezier(0.17, 0.67, 0.83, 0.67), background 0.4s cubic-bezier(0.17, 0.67, 0.83, 0.67); }
.center input[type=checkbox]:checked + label {
    background-color: var(--main);
    box-shadow: 1px 1px 1px 1px rgba(3, 79, 32, 0.5), 0 0 0 3px var(--white) inset; }

.center .bg-main input[type=checkbox] + label {
    border: solid 1px var(--main) !important;
 }

 .center.bg-main input[type=checkbox]:checked + label {
    background-color: var(--main);
 }

 .center.bg-blue input[type=checkbox] + label {
    border: solid 1px #00a0ea !important;
 }


 .center.bg-light-blue input[type=checkbox] + label {
    border: solid 1px #4dd2ff !important;
 }
 
 .center.bg-green input[type=checkbox] + label {
    border: solid 1px #75bc00 !important;
 }

 .center.bg-blue input[type=checkbox]:checked + label {
    background-color: #00a0ea !important;
 }

 .center.bg-dark-blue input[type=checkbox] + label {
    border: solid 1px #3a419b !important;
 }

 .center.bg-dark-blue input[type=checkbox]:checked + label {
    background-color: #3a419b !important;
 }

 .center.bg-green input[type=checkbox]:checked + label {
    background-color: #75bc00 !important;
 }

 .center.bg-light-blue input[type=checkbox]:checked + label {
    background-color: #4dd2ff !important;
 }
 
  /*************************/
 /*  SORT BTNS / CHECKES  */
/*************************/
.sortBtnBox button {
    color: var(--main_blue); }

    .sortBtnBox button:after,
    .sortBtnBox .ascending:after,
    .sortBtnBox .descending:after {
        font-family: 'univers';
        font-size: 0.8rem;
        padding-left: 6px; }
    .sortBtnBox button:after {
      content: "\71"; }
    .sortBtnBox .ascending:after {
        content: "\6f"; }
    .sortBtnBox .descending:after {
        content: "\70"; }
.sortBtnBox button:hover {
    color: var(--sec_blue); }

  /************/
 /*  BUTTON  */
/************/
button:focus,
button:hover,
button:active {
    outline: none !important; }

  /***************/
 /*  CHART BAR  */
/***************/
.barChartBox {
  position: relative;
  width: 100%;
  min-height: 50px;
  z-index: 1; }
.barChart { padding: 30px 20px 0 0; }
.bar {
    margin: 0.2rem 0;
    display: block; }
.chartRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  width: 100%; }
.labelBox { 
    width: 12rem;
    padding-right: 8px;
    text-align: right;
    font-size: 0.8rem; }
.bars { 
  flex-grow: 1;
  margin-bottom: 4px; }
.lines {
  position: absolute;
  top: 0; bottom: 0;
  width: 100%;
  z-index: -1;
  display: flex;
  flex-flow: row nowrap;
  padding: 20px 20px 0 0;
  text-align: right;
  color: grey;
  font-size: 0.8rem; }
  .lines span {
    position: relative;
    top: -17px;
    right: -5px; }
    .solidLine span { right: -12px !important; }
  .solidLine {
    border-right: 1px solid grey; }
  .dotLines {
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap; }
    .dotLines div {
      background-image: linear-gradient(to bottom, #333 10%, rgba(255, 255, 255, 0) 0%);
      background-position: right;
      background-size: 1px 10px;
      background-repeat: repeat-y;
      flex-grow: 1; }
      
@media (max-width: 511px) {
  .labelBox { 
    width: 9rem;
    font-size: 0.7rem; }
}
@media (max-width: 383px) {
  .labelBox { 
    width: 6rem;
    font-size: 0.6rem; }
}


  /*******************/
 /*  CHART TOOLTIP  */
/*******************/
.barTooltipe {
  color: black !important;
  background-color: var(--pale_blue) !important;
  -moz-box-shadow:    0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow:         0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
.barTooltipe:after {
  border-top-color: var(--pale_blue) !important; }
.barTooltipe h3 { 
  font-size: 1.5rem !important;
  font-weight: bold !important;
  color: var(--main_blue) !important; }
.barTooltipe h3 { 
  font-size: 1.5rem !important;
  font-weight: bold !important;
  color: var(--main_blue) !important; }
.barTooltipe p { 
  padding-bottom: 5px !important; }
.barTooltipe .tollValue { 
  text-align: right !important;
  font-size: 2rem; }

  /*****************/
 /*  MAP TOOLTIP  */
/*****************/
.map_z_info { z-index: 2; }
.jvectormap-tip {
    padding: 3px 10px 10px !important;
    background-color: white !important; }
.jvectormap-tip h1,
.jvectormap-tip h4 {
    color: var(--main_blue);
    font-weight: bold;
    text-align: left;
    margin: 5px; }
.jvectormap-tip h1 {
    font-size: 1.4rem; }
.jvectormap-tip h4 {
    font-size: 0.8rem; }
.jvectormap-tip p {
    font-size: 0.8rem;
    color: var(--txt_black_1);
    display: flex;
    padding: 0 5px; }
.jvectormap-tip span {
    width: 16px; height: 16px;
    margin-right: 4px;
    border-radius: 50%; }
.jvectormap-tip span.bg-map_progress {
   background-color: #f36c21; }
.jvectormap-tip span.bg-map_available {
    background-color: #7cfc00; }
.jvectormap-tip span.bg-map_n_strategy {
   background-color: gray; }
.jvectormap-tip span.bg-map_n_regulations {
   background-color: black; }


/* IMG MAP */
.imgMapContainer {
  width: 1000px;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain; }

area { cursor:pointer; }

.imgMapContainer div {
  width: 1000px !important; }

/* @media (max-width: 1023px) {
  .imgMapContainer,
  .imgMapContainer div {
    width: 700px !important; }
}
@media (max-width: 767px) {
  .imgMapContainer,
  .imgMapContainer div {
    width: 580px !important; }
}
@media (max-width: 639px) {
  .imgMapContainer,
  .imgMapContainer div {
    width: 450px !important; }
}
@media (max-width: 511px) {
  .imgMapContainer,
  .imgMapContainer div {
    width: 350px !important; }
}
@media (max-width: 383px) {
  .imgMapContainer,
  .imgMapContainer div {
    width: 290px !important; }
} */



.notes_box { 
  margin: 0.5rem 0;
  padding: 0.25rem 0.5rem;
  border: solid 1px var(--txt_black_1);
  font-size: 0.75rem;
  line-height: 1rem; }
.notes_box p { 
  padding: 0; }

.modalBtn {
  color: var(--main_blue); }

.parallax {
  min-height: 300px; 
  background-attachment: fixed;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover; }

.cover {
  min-height: 300px; 
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover; }

.npPad { padding: 0 !important;}

.bg-header_top_bg {
    background-color: #00a1e9;
}

.bg-main {
    background-color: #751a71;
}

.bg-main_dark:hover {
    background-color: #360134;
}

.home-title {
    font-size: 6em;
}

.home-subtitle {
    font-size: 6rem;
}

.max-w-640 {
    max-width: 640px;
}

.text-bold {
    font-weight: bold !important;
}

.hover:text-bold {
    font-weight: bold;
}

.text-black {
    color: #000000;
}

.message-title {
    font-size: 1.7rem;
    color: #751A71;
    font-weight: bold;
    line-height: 2rem;
    margin-bottom: 10px;
}

.message-title-small {
    font-size: 1.3rem;
    color: #751A71;
    font-weight: bold;
    line-height: 1.7rem;
    margin-bottom: 10px;
}

.message-note {
    margin-top: 10px;
    font-size: 10px;
}

.message {
    margin-top: 10px;
    display: inline-grid;
}

.title-section {
    background-color: #751a71;
    font-weight: bold;
    color: white;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 40px;
    padding-top: 2px;
}

.title {
    color: #00A1E9; 
    font-size: 2.8rem;
    font-weight: bold;
    line-height: 2.8rem;
    margin-top: 0px;
    margin-bottom: 20px;
}

.margin-top-less-5 {
    margin-top: -5px;
}

.list {
    list-style: inside;
    
}

.pages p {
    padding-top: 5px;
    padding-bottom: 5px;
}

.separator {
    border-bottom: 0.5px solid black;
    margin-top: 30px;
    margin-bottom: 30px;
}

.stories {
    margin-top: 20px;
    margin-bottom: 20px;
}

.legend {
    color: #751a71;
    font-size: 10px;
    line-height: 5px;
    position: relative;
    margin-left: 10px;
    margin-top: -90px;
    margin-bottom: 50px;
}

.custom-tooltip {
    border-color: #751a71 !important;
    background-color: white !important;
    color: black !important;
    width: fit-content;
    word-break: break-all;
   
}

.__react_component_tooltip {
    max-width: 50vw !important;
    margin-top: 15px !important;
    margin-left: -15px !important;
}

.__react_component_tooltip::after {
    display: none !important;
}

.custom-tooltip a {
    word-break: break-all
}

.pointer {
    cursor: pointer;
}

.custom-link {
    color: #751a71 !important;
    cursor: pointer !important;
    font-weight: bold;
}

.full-w-grey {
    left: 0px;
    background-color: #F0F0F0;
    width: calc(100% + 8rem);
    margin-top: 50px;
    padding-top: 20px;
    margin-left: -4rem;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-bottom: 30px;
    padding-bottom: 50px;
}

.full-w-grey img {
    width: 100% !important;
}

.text-main {
    color: var(--main);
}

.lh-5 {
    line-height: 17px;
    padding-top: 0px !important;
    padding-bottom: 0px!important;
}

.text-main {
    color: var(--main);
    font-weight: 400;
}

.text-blue {
    color: #00a0ea;
    font-weight: 400;
}

.text-light-blue {
    color: #4dd2ff;
    font-weight: 400;
}

.text-dark-blue {
    color: #3a419b;
    font-weight: 400;
}

.text-dark-green {
    color: #75bc00;
    font-weight: 400;
}

.filter-div {
    border: 2px solid var(--main);
    width: fit-content;
    padding: 10px;
}

.background-main {
    background-color: var(--main);
}

.background-dark-blue {
    background-color: #3a419b;
}

.background-blue {
    background-color: #00a0ea;
}

.background-light-blue {
    background-color: #4dd2ff;
}

.align-right {
    text-align: right;
}

.display-table {
    display: table !important;
    margin: auto;
}

.legend-red {
    width: 20px;
    height: 20px;
    background-color: #f30000;
    float: left;
}

.legend-yellow {
    width: 20px;
    height: 20px;
    background-color: #ffc100;
    float: left;
}

.legend-green {
    width: 20px;
    height: 20px;
    background-color: #75bc00;
    float: left;
}

.legend-grey {
    width: 20px;
    height: 20px;
    background-color: #d7d8d9;
    float: left;
}

.vh-17 {
    margin-top: 17vh;
}

.image-credit {
    font-size: small;
    color: white;
    text-align: right;
    margin-top: -60px;
    padding-right: 20px;
    margin-bottom: 60px;
}

.font-italic {
    font-style: italic !important;
}

.no-overflow-y {
    overflow-y: hidden;
}