@font-face { /* -> THIN */
    font-family: 'Univers';
    src: url('../fonts/univers/Univers.woff') format('woff');
    font-weight: 200;
    font-style:normal; }
@font-face { /* -> THIN */
    font-family: 'Univers';
    src: url('../fonts/univers/Univers.woff') format('woff');
    font-weight: 200;
    font-style:italic; }
@font-face { /* -> LIGHT */
    font-family: 'Univers';
    src: url('../fonts/univers/Univers.woff') format('woff');
    font-weight: 300;
    font-style:normal; }
@font-face { /* -> LIGHT */
    font-family: 'Univers';
    src: url('../fonts/univers/Univers.woff') format('woff');
    font-weight: 300;
    font-style:italic; }
@font-face { /* -> REGULAR */
    font-family: 'Univers';
    src: url('../fonts/univers/Univers.woff') format('woff');
    font-weight: 400;
    font-style: normal; }
@font-face { /* -> ITALIC */
    font-family: 'Univers';
    src: url('../fonts/univers/Univers.woff') format('woff');
    font-weight: 400;
    font-style: italic; }
@font-face { /* -> MEDIUM */
    font-family: 'Univers';
    src: url('../fonts/univers/Univers.woff') format('woff');
    font-weight: 500;
    font-style: normal; }
@font-face { /* -> MEDIUM */
    font-family: 'Univers';
    src: url('../fonts/univers/Univers.woff') format('woff');
    font-weight: 500;
    font-style: italic; }
@font-face { /* -> BOLD */
    font-family: 'Univers';
    src: url('../fonts/univers/UniversBlack.woff') format('woff');
    font-weight: 700;
    font-style:normal; }
@font-face { /* -> BOLD */
    font-family: 'Univers';
    src: url('../fonts/univers/UniversBlack.woff') format('woff');
    font-weight: 700;
    font-style:italic; }
@font-face { /* -> BLACK */
    font-family: 'Univers';
    src: url('../fonts/univers/UniversBlack.woff') format('woff');
    font-weight: 900;
    font-style: normal; }
@font-face { /* -> BLACK */
    font-family: 'Univers';
    src: url('../fonts/univers/UniversBlack.woff') format('woff');
    font-weight: 900;
    font-style: italic; }